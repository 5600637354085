// General

////////////////
//
//  Fonts
//
////////////////

//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&family=Roboto:wght@300;400;500;600;700&display=swap');

////////////////
//
//  Colors
//
////////////////

@blue: #194f90;
@black: #000;
@white:#fff;
@grey: rgb(247, 247, 247);

.grey {
  background-color: @grey;
}

////////////////
//
//  General Styles
//
////////////////

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: @black;
}

* {
  -webkit-locale: auto;
  white-space: normal;
}

*, ::after, ::before {
  box-sizing: border-box !important;
}

h1, .h1,
h2, .h2 {
  font-family: 'Montserrat', sans-serif;
}

// TODO: обновить глобальные стили для h1
h1 .h1 {
  //font-size: 67px;
  //line-height: 1.15em;
}

h2, .h2 {
  font-size: 50px;
  display: inline-block;
  width: auto;
  max-width: max-content;
  position: relative;
  text-transform: uppercase;

  // important for tilda
  padding-bottom: 10px;
  margin-bottom: 15px;
  &:after {
    content: "";
    position: absolute;
    width: 60px;
    bottom: 0;
    left: 0;
    border-bottom: 6px solid @blue;
  }
  span {
    color: #194f90;
    text-decoration: underline;
    //display: inline;
    //position:relative;
    //&:after{
    //  content: "";
    //  position: absolute;
    //  bottom: 0px;
    //  left: 0;
    //  border-bottom: 3px solid #194f90;
    //  width: 100%;
    //}
  }
  &.no-underline:after{
    border-color: transparent;
  }
}

.small-text {
  font-size: 14px;
  line-height: 18px;
}

.no-scroll {
  overflow-y: hidden;
}

.default-font-weight {
  font-weight: 400 !important;
  * {
    font-weight: 400 !important;
  }
}

div#rec208949457, div#rec208959723 {
  .t827__grid-item{
    border-radius: 10px !important;
    overflow: hidden;
  }
  img {
    border-radius: 10px !important;
  }
  img + div {
    border-radius: 10px !important;
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: @blue;
  }
}

.selected-text {

  position: relative;
  max-width: max-content;
  width: auto;
  display: inline-block;
  z-index: 1;

  a, span {
    display: inline;
    background: linear-gradient(180deg,rgba(255,255,255,0) 0,#cafccc 15%);
    padding: 3px 5px;
    margin-left: -5px;
  }

}

span.selected-text {
	display: inline;
	background: linear-gradient(180deg,rgba(255,255,255,0) 0,#cafccc 15%);
	padding: 3px 5px;
	margin-left: -5px;
}

section:not(.section-tilda) {
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.section {
  padding: 60px 0 150px 0;
}

body.home {
  .section {
    @media screen and (min-width: 768px) {
      padding: 80px 0 95px 0;
    }
    h2 {
      margin-top: 0;
    }
  }
}

.breadcrumbs {
  color: #194f90;
  font-weight: 700;
  margin-bottom: 10px;
  a {
    color: #9e9e9e;
  }
}

.breadcrumb.v2 {
  margin: 25px 0;
  .breadcrumb-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #9e9e9e;
    align-items: center;
    .breadcrumb-list-item {
      position: relative;
      margin: 0 10px;
      padding: 14px 0;

      &:first-child {
        margin-left: 0;
      }

      i {
        margin-left: 3px;
      }

      &:hover {
        cursor: pointer;
        & > a{
          color: @blue;
        }
        svg {
          fill: @blue;
          //path {
          //  stroke: @blue;
          //}
        }
        .breadcrumb-dropdown-list {
          display: block;
        }
      }

    }

    .breadcrumb-dropdown-list {
      position: absolute;
      min-width: 150px;
      width: max-content;
      padding: 15px 15px;
      list-style: none;
      top: 44px;
      left: -15px;
      border-radius: 5px;
      box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      overflow: hidden;
      display: none;
      margin: 0;
      li {
        margin-bottom: 7px;
        a {
          padding: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }

}

.paginate_links {

  justify-content: center;
  display: flex;
  margin-top: 20px;

  .page-numbers {
    display: none;

    &.next {
      svg {
        margin-left: 15px;
      }
    }
    &.prev {
      svg {
        margin-right: 15px;
      }
    }

    &.next,
    &.prev{
      //display: inline-block;
      border-radius: 30px;
      border: solid 3px #194f90;
      padding: 17px 22px;
      color: #194f90;
      font-size: 18px;
      min-width: 181px;
      text-align: center;
      margin: 15px;
      display: flex;
      align-content: center;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      &:hover{
        background-color: #194f90;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }

    }

  }
}

#cookie-notice #cn-accept-cookie {
  background: rgb(202, 252, 204);
  color: #000;
  border-radius: 16px;
  min-width: 70px;
  //border: 1px solid rgb(202, 252, 204);
  &:hover {
    background: #5bb75e;
  }
}

.no-border, .no-border * {
  border: 0 !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

////////////////
//
//  General Elements
//
////////////////

.logo {

  width: 210px;
  height: 60px;

  a {
    display: block;
    position: relative;
    z-index: 10;
  }

  svg {
    width: 100%;
    height: auto;
  }

  span {
    font-size: 8px;
    position: absolute;
    right: 0;
    bottom: -5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: @black;
  }

}

.lang-en {
  .logo {
    span {
      font-size: 9.3px;
    }
  }
}

.lang-ua {
  .mobile-menu-logo,
  .logo {
    span {
      font-size: 8.75px;
      @media screen and (max-width: 767px) {
        font-size: 9.5px;
      }
      @media screen and (max-width: 576px) {
        font-size: 8.7px;
      }
    }
  }
}

.button {
  border-radius: 30px;
  border: solid 3px @blue !important;
  padding: 16px 22px;
  text-decoration: none;
  color: @white;
  background-color: @blue;
  font-weight: 500;
  &:hover {
    color: @blue;
    background-color: transparent;
  }
}

.underline {
  //text-decoration: underline;
  display: inline;
  border-bottom: 1.3px solid rgba(0,0,0,.2);
}

.default-page {
  h1 {
    margin-top: 25px !important;
    margin-bottom: 40px !important;
    position: relative;
    padding-bottom: 25px !important;
    display: inline-block;
    width: auto;
    max-width: max-content;
    position: relative;
  }
  .breadcrumb {
    margin: 0 !important;
  }
  .sidebar-wrap {
    .sidebar-section:first-child {
      h3 {
        margin-top: 40px;
        margin-bottom: 25px;
      }
    }
  }
}

.underline-blue {

  //font-size: 50px;
  display: inline-block;
  width: auto;
  max-width: max-content;
  position: relative;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 15px;

  &:after {
    content: "";
    position: absolute;
    width: 60px;
    bottom: 0;
    left: 0;
    border-bottom: 6px solid @blue;
  }
  span {
    color: #194f90;
    text-decoration: underline;
  }
  &.no-underline:after{
    border-color: transparent;
  }

}

#cn-close-notice {
  display: none !important;
}

.is-provider-slideshare div.wp-block-embed__wrapper div {
  display: none;
}

////////////////
//
//  Form Styles
//
////////////////

form {

  input, textarea {
    border-radius: 10px;
    background-color: #f7f7f7;
    //border: 0;
    padding: 10px 20px;
    border: 2px solid #f7f7f7;
    -webkit-appearance: none !important;
    &.error {
      border-color: #d40808;
    }
  }

  .send-popup-form {
    &.sending {

      position: relative;

      &:after {
        position: absolute;
        content: "";
        top: 15px;
        right: -35px;
        width: 20px;
        height: 20px;
        background-image: url(../img/35.gif);
        background-size: contain;
      }

    }
  }

}

.page-template-page-no-sidebar, .page-template-page-empty-sidebar {
  h1 {
    font-size: 24px !important;
  }
}

.fancybox-bg {
  background: #fff;
}
.fancybox-container {
  .fancybox-slide {
    padding: 60px 5px;
  }

  .fancybox-button {
    width: 60px;
    height: 60px;
    background: transparent !important;
    color: #000 !important;
  }
}

.bg-light-blue {
    background-color: #E7F1FF;
}
.bg-green {
    background-color: #CAFCCC;
}

.rounded-box {
  border-radius: 10px;
  padding: 33px;
}
.text-decoration-none {
  text-decoration: none;
}
.text-black {
  color: @black !important;
}
.m-0 {
  margin: 0 !important;
}
.mb-1 {
  margin-bottom: .2rem !important;
}
.mb-2 {
  margin-bottom: .5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 2rem !important;
}
.mb-6 {
  margin-bottom: 2.5rem !important;
}
.g-5{
  row-gap: .75rem !important;
}

p:empty {
  display: none;
}


@media screen and (max-width: 1200px) {
  .logo {
    margin: 25px 0;
  }
}

@media screen and (max-width: 767px) {

  .section {
    padding: 40px 0 70px 0;
  }

  h2, .h2, .default-page h1{
    font-size: 30px;
    margin-top: 0;
  }

  .logo {
    width: 230px;
    height: auto;
    span {
      right: 0;
      bottom: -3px;
      font-size: 8.8px;
    }
  }

  .lang-en {
    .logo {
      span {
        font-size: 10.15px;
      }
    }
  }

}

@media screen and (max-width: 576px) {
  .logo {
    width: 210px;
    span {
      right: 0;
      bottom: -3px;
      font-size: 7.7px;
    }
  }
  .h2, h2 {
    font-size: 26px;
  }
  .h2:after, h2:after {
    width: 45px;
    border-bottom: 5px solid #194f90;
  }
  .button {
    font-size: 14px;
  }

  .lang-en {
    .logo {
      span {
        font-size: 9.3px;
      }
    }
    .mobile-menu-logo a span {
      font-size: 9.3px;
    }
  }
}

.removed_link, a.removed_link {
  text-decoration: line-through;
}

.removed_link:after {
  display: inline-block;
  text-decoration: none;
  content: " (the link is broken)";
}

body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
  --wp--preset--duotone--dark-grayscale: url('#wp-duotone-dark-grayscale');
  --wp--preset--duotone--grayscale: url('#wp-duotone-grayscale');
  --wp--preset--duotone--purple-yellow: url('#wp-duotone-purple-yellow');
  --wp--preset--duotone--blue-red: url('#wp-duotone-blue-red');
  --wp--preset--duotone--midnight: url('#wp-duotone-midnight');
  --wp--preset--duotone--magenta-yellow: url('#wp-duotone-magenta-yellow');
  --wp--preset--duotone--purple-green: url('#wp-duotone-purple-green');
  --wp--preset--duotone--blue-orange: url('#wp-duotone-blue-orange');
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
}

:where(.is-layout-flex) {
  gap: 0.5em;
}

body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
  display: flex;
}

body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}

body .is-layout-flex > * {
  margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}

.has-black-color {
  color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
  color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
  color: inherit;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}

.wp-block-pullquote {
  font-size: 1.5em;
  line-height: 1.6;
}

@media screen and (min-width: 576px) {
  .extra-text {
    font-size: 18px;
    line-height: 27px;

    a {
      text-decoration: underline;
    }
  }
}