.section-jobs, #rec231821491, #rec451025656 {

  .t-col.t-col_10 {
    float: none;
    width: 100%;
  }

  #work-jobs-list {

    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    margin: 90px 0;

    & + p {
      text-align: center;
    }

    li {
      border-radius: 16px;
      background-color: #f7f7f7;
      position: relative;
      display: flex;
      margin: 0 1%;
      //font-size: 0;
      font-size: 18px;
      min-height: 150px;
      padding: 30px;
      //max-width: 370px;
      flex-direction: column;
      flex-wrap: wrap;
      padding-bottom: 85px;
      margin-bottom: 20px;
      box-sizing: border-box !important;
      font-weight: 500;
      cursor: pointer;
      flex: 0 0 31.333%;
      max-width: 31.333%;

      @media screen and (max-width: 991px) {
        flex: 0 0 48%;
        max-width: 48%;
      }

      @media screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &:after{
        position: absolute;
        bottom: 25px;
        left: 35px;
        width: 35px;
        height: 35px;
        background-image: url(https://roman.ua/wp-content/uploads/tilda/1297774/pages/10593193/tild6235-3133-4233-b736-313839353233__arrow2.svg);
        background-position: center;
        content: "";
        background-repeat: no-repeat;
        transition: .7s;
      }
      a {
        font-weight: 600;
        color: #000;
        font-size: 30px;
        text-align: left;
        text-decoration: underline;
        margin-bottom: 30px;
        text-underline-position: under;
        line-height: 44px;
      }
      b{
        font-size: 18px;
        color: #000;
        font-weight: 500;
      }
      br{
        display: none;
      }
      &:hover {
        background-color: #194f90;
        color: #fff !important;
        *{
          color: inherit !important;
        }
        &:after{
          left: 45px;
          background-image: url(https://roman.ua/wp-content/uploads/2020/10/white-1.svg);
        }
      }
    }

  }

}

@media screen and (max-width: 960px) {

  .section-jobs #work-jobs-list, #rec231821491 #work-jobs-list {
    margin: 40px 0;
    li {
      flex: 0 0 48%;
      max-width: 48%;
    }
  }

}

@media screen and (max-width: 767px) {

  .section-jobs #work-jobs-list, #rec231821491 #work-jobs-list {
    margin: 40px 0;
    li {
      flex: 0 0 98%;
      max-width: 98%;
    }
  }

}
