// Tilda

.section.section-tilda {
  padding-top: 0 !important;
}

.section-tilda {

  padding: 0;

  *, :after, :before {
    box-sizing: border-box !important;
  }

  #form451025659,
  #form241183889 {

    *, :after, :before {

      box-sizing: border-box !important;

    }

  }

  //img {
  //  width: initial;
  //  max-width: initial;
  //  height: initial;
  //}

  #allrecords a:hover, a:hover {color: #194f90 !important;}
  #allrecords .t386__uptitle a {color: #ffffff; text-decoration: underline;}
  #allrecords .t386__uptitle a:hover {color: #cafccc !important; text-decoration: underline;}
  #allrecords .tn-elem__2051080491592989704249 a, #allrecords .tn-elem__2051080491592989704249 a:hover {text-decoration:none; }

  #rec225736320 .tn-elem[data-elem-id="1591276071661"] .tn-atom:hover {
    background-color: #194f90;
    color: #ffffff !important;
  }

  h2, .h2 {
    &:after {
      display: none;
    }
  }

}

.section {

  p {
    margin-top: 22px;
    margin-bottom: 22px;
  }

  h2, .h2 {
    padding-bottom: 25px;
    margin-bottom: 15px;
    margin-top: 42.5px;
  }

}

@media screen and (max-width: 960px) {

  .section-tilda {

    .t-container .t-width,
    .t-container .t-col{
      box-sizing: border-box !important;
    }

  }

}

#chartdiv {
  height: 280px;
  width: 100%;
}
@media screen and (min-width: 767px) {
  #chartdiv {
    height: 580px;
  }
}

.t958__card {
    box-sizing: border-box !important;
}