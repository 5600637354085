.section-subscribe {

  background: #E7F1FF;
  padding: 196px 0 !important;

  .subscribe-home {
    max-width: 870px;
    margin: 0 auto;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    max-width: 610px;
    margin-top: 0;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }

  div.sp-form[sp-id="156261"] {
    background: transparent;
    .sp-field {
      width: 100%;
      max-width: 342px;
      margin-right: 12px;
      input {
        border-radius: 0 !important;
        -webkit-appearance: none;
        &::placeholder {
          font-weight: normal;
        }
      }
      .sp-button {
        background: @blue;
        border-radius: 30px;
        min-width: 170px;
        text-align: center;
      }
    }
  }

}

@media screen and (max-width: 767px){
  .section-subscribe {
    padding-top: 50px !important;
    padding-bottom: 60px !important;
    h3 {
      font-size: 26px;
      line-height: 31.69px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    div.sp-form[sp-id="156261"] {
      form.sp-element-container {
        flex-direction: column;
        .sp-field {
          max-width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}
