// Tilda

.sidebar-wrap {

  height: 100%;
  position: relative;

  .sidebar-section {

    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 30px;
    margin-bottom: 10px;

    .underline {
      border-bottom: initial;
      text-decoration: underline;
    }

    h3 {
      position: relative;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 15px;
      font-family: Montserrat, sans-serif;
      line-height: 1;
    }

    &.is_stuck {
      h3 {
        margin-top: 30px;
      }
    }

    .number {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 14px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(100%, -50%);
      background: transparent;
    }

    p {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .button {
      padding: 8px 40px;
      border-radius: 30px;
      margin-top: 5px;
      display: inline-block;
    }

    ul {

      padding-left: 25px;
      list-style: none;

      li {

        position: relative;
        margin-bottom: 10px;

        a {
          //text-decoration: underline;
          font-size: 18px;
          line-height: 1.26;
          display: inline;
          border-bottom: 1.3px solid rgba(0,0,0,.2);
          &:hover {
            color: #194f90;
            border-bottom: 1px solid #194f90;
          }
        }

        &:before {
          content: "\2022";
          color: #194f90;
          font-weight: 700;
          display: inline-block;
          width: 15px;
          margin-left: -20px;
          font-size: 24px;
          position: absolute;
          left: 0;
          height: 100%;
          line-height: 1;
        }

      }

    }

    &:last-child, &.is_stuck {
      border-bottom: 0;
      min-height: initial;
    }

    &.subscribe {
      min-height: 530px;
    }

  }

  form {

    position: relative;

    input {
      width: 100%;
    }

    .search-submit {
      position: absolute;
      border: 0;
      background: 0 0;
      padding: 3px;
      width: 25px;
      height: 25px;
      top: 8px;
      right: 8px;
    }

  }

  .search {
    margin-top: 35px;
  }

  .subscribe {
    position: sticky;
    top: 20px;
  }

}


@media screen and (max-width: 992px) {

  .sidebar-wrap {

    .sidebar-section.subscribe {
      min-height: initial;
    }

  }

}

@media screen and (max-width: 767px) {

  .sidebar-wrap {

    .sidebar-section {
      h3 {
        font-size: 26px;
      }
      &:first-child h3{
        margin-top: 0;
      }
    }

  }

}
