// Cloents

.section-clients {

  .sub-title-wrap {
    p {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .button-wrap {
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .clients-logo {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      flex: 0 0 25%;
      max-width: 25%;
      height: 150px;
      margin-top: 80px;
      align-self: center;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid #e2e2e2;
      img {
        max-width: 75%;
      }
      picture {
        max-width: 75%;
        img{
          max-width: 100%;
        }
      }
    }
  }

}

@media screen and (max-width: 991px) {
  .section-clients {
    .clients-logo {
      li {
        height: 100px;
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: 767px) {

  .section-clients {
    .clients-logo {
      li {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

}

@media screen and (max-width: 575px) {

  .section-clients {
    padding-top: 20px;
    padding-bottom: 30px;
    .sub-title-wrap {
      p{
        font-size: 18px;
        margin: 3px 0 29px 0;
      }
    }
    .button-wrap {
      justify-content: flex-start;
    }
  }

}
