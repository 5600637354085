.section-conference {

  h2 {
    font-family: "Montserrat";
    text-transform: initial;
    line-height: 1.1em;
    font-size: 30px;
    &:after {
      display: none;
    }
    a {
      display: initial;
      border-bottom: 3px solid #000;
      &:hover{
        border-color: @blue;
      }
    }
  }

  .filtered-image {

    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-blend-mode: normal, color, normal;
    background-position: center !important;

    & + .hover-image {
      position: absolute;
      top:0;
      left:0;
      opacity: 0;
      transition: .2s;
      z-index: 2;
    }

  }

  .article {
    background-color: #fff;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    height: calc(100% - 30px);

    .article-link {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .article-thumbnail {
      width: 100%;
      height: 390px;
      a {
        display: block;
        position: relative;
        &:after{
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top:0;
          z-index:9;
          background-color: #157cf9;
          opacity: .4;
        }

      }
      * {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .article-details {
      padding: 30px 35px 45px 35px;
      .category {
        font-weight: bold;
      }
      .date {
        color: rgba(0, 0, 0, 0.6);
        padding: 3px 5px;
      }
      h3 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 1.2em;
        &:after{
          display: none;
        }
        a {
          border-bottom: 1.3px solid rgba(0,0,0,.2);
        }
      }
      p {
        display: block;
      }
      .d-flex {
        *:first-child {
          margin-right: auto;
          font-weight: normal;
        }
      }
      &>*:first-child {
        margin-top: 0;
      }
      &>*:last-child {
        margin-bottom: 0;
      }
    }

    h3 {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.17;
      color: #000000;
    }

    &-small.article {

      margin-bottom: 30px;
      height: initial;
      overflow: visible;
      //height: calc(100% - 30px);

      .article-thumbnail {
        height: 208px;
      }

      .article-details {
        padding: 15px 19px 25px 19px;
        h3 {
          font-family: "Montserrat";
          font-size: 21px;
          line-height: 1.2em;
        }
      }

      //&:last-child {
      //  margin-bottom: 0;
      //}

    }

  }

  .button {
    max-width: 310px;
    text-align: center;
    margin: 50px auto 0 auto;
    cursor: pointer;
  }

}

@media screen and (min-width: 993px){
  .section-conference .article:hover {
    .hover-image {
      opacity: 1;
    }
    a {
      color: #194f90;
      border-color: #194f90!important;
    }
  }
}

@media screen and (max-width: 992px){
  .section-conference {
    .article {
      height: initial;
      margin-bottom: 30px;
      &.article-small {
        height: initial;
        .article-thumbnail {
          height: 390px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .section-conference {

    padding-top: 40px !important;
    padding-bottom: 45px !important;

    h2 {
      font-size: 26px;
    }

    .row > .col-12 {
        .article {
        background-color: transparent;
        margin-bottom: 19px;
        position: relative;
        padding-bottom: 5px;
        .article-thumbnail {
          display: none;
        }
        .article-details {
          padding: 0 0 0 17px;
        }
        &:before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 90px;
          top: 10px;
          left: 2px;
          overflow: hidden;
          background-color: @blue;
        }
      }
      &:nth-child(1) .article, &:nth-child(2) .article {
        background-color: #fff;
        .article-thumbnail {
          display: block;
          height: 209px !important;
        }
        .article-details {
          padding: 15px 19px 35px 19px;
          h3 {
            font-size: 21px;
            margin-bottom: 0;
          }
          p {
            display: none;
          }
        }
        .article-small {
          .article-thumbnail {
            display: none;
          }
        }
      }
    }

  }
}
