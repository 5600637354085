.section-404 {
  padding-top: 0;
  padding-bottom: 50px;
  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 300px;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.1);
    margin: auto;
    text-align: center;
    span {
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      color: rgba(25, 79, 144, 0.2);
    }
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
  }
  .sidebar-wrap {
    .sidebar-section {
      margin-top: 0;
      .search {
        max-width: 400px;
        margin: 15px auto 0 auto;
        input {
          background-color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 992px){
  .section-404 {
    h1 {
      font-size: 200px;
    }
  }
}

@media screen and (max-width: 767px){
  .section-404 {
    h1 {
      font-size: 70px;
    }
    p {
      font-size: 20px;
      line-height: 27px;
    }
  }
}
