// Request

.request-popup-background {
  background-color: @white;
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: .4s;
  opacity: 0;
}

.request-popup {

  display: none;
  position: fixed;
  z-index: 102;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  //display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  overflow-y: auto;

  .container {
    position: relative;
    padding: 75px 15px 15px 15px;
  }

  h2 {
    max-width: 344px;
    width: 100%;
    margin-top: 0;
    display: block;

    // Падинги ломаются на странице тильды.
    padding-bottom: 10px;
    margin-bottom: 15px;

  }

  img {
    width: 71px;
    height: auto;
    border-radius: 90px;
  }

  .text-wrap {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 40px 0;

    div {
      padding-left: 25px;
      p {
        display: block;
        margin: 0;
      }
      p:nth-child(1) {
        font-size: 18px;
        line-height: 20px;
      }
      p:nth-child(2) {
        font-size: 22px;
        line-height: 24px;
        font-weight: 500;
        text-decoration: underline;
      }
      p:nth-child(3) {
        font-size: 14px;
        line-height: 21px;
      }
    }

  }

  .form-row {

    margin-bottom: 25px;
    position: relative;

    input,
    textarea {
      width: 100%;
    }

    .iti {
      width: 100%;
    }

    span {
      color: rgba(0, 0, 0, 0.5);
      margin-top: 10px;
      display: inline-block;
      a{
        text-decoration: underline;
      }
    }

    .button {
      display: inline-block;
      padding: 16px 40px;
    }

  }

  .close-request-popup {
    position: absolute;
    right: 15px;
    top: 25px;
    width: 32px;
    height: 32px;
  }
  .close-request-popup:hover {
    opacity: 1;
  }
  .close-request-popup:before, .close-request-popup:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 3px;
    background-color: @blue;
  }
  .close-request-popup:before {
    transform: rotate(45deg);
  }
  .close-request-popup:after {
    transform: rotate(-45deg);
  }

}


.iti {

  &__divider {
    margin: 0 !important;
    padding: 0 !important;
  }

  &__country {
    background-color: #f7f7f7;
    padding: 10px 20px 10px 6px;

    &-list {
      min-width: 300px;
      border: 2px solid #f7f7f7;
      border-radius: 10px;
    }

    &-name {
      margin-top: 0 !important;
    }
  }

  &__dial-code {
    margin-top: 0 !important;
  }

  &-mobile {
    .iti--container {
      left: 15px;
      right: 15px;
    }
  }

  @media screen and (max-width: 991px) {
    &--container {
      &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .75;
        z-index: 1;
      }
    }
  }
}

.default-popup-background {
  background-color: #000;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: .2s;
  -o-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
  opacity: 0.2;
  display: none;
}
.default-popup {

  display: none;
  position: fixed;
  z-index: 100;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  overflow-y: auto;

  .container {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .text-wrap {
    position: relative;
    background-color: #fff;
    width: 100%;
    max-width: 671px;
    padding: 60px;
    border-radius: 8px;
  }

  p {
    font-size: 18px;
    margin-bottom: 18px;
  }

  .close-default-popup {
    position: absolute;
    right: 15px;
    top: 25px;
    width: 23px;
    height: 23px;
  }
  .close-default-popup:hover {
    opacity: 1;
  }
  .close-default-popup:before, .close-default-popup:after {
    position: absolute;
    left: 8px;
    content: ' ';
    height: 23px;
    width: 3px;
    background-color: @blue;
  }
  .close-default-popup:before {
    transform: rotate(45deg);
  }
  .close-default-popup:after {
    transform: rotate(-45deg);
  }

}

@media screen and (max-width: 576px) {
  .default-popup .text-wrap {
    padding: 40px 30px;
  }
  .request-popup-background {
    border-radius: 30%;
  }
  .request-popup {
    h2 {
      padding-right: 20px;
      padding-top: 42.5px;
      margin-top: 0 !important;
    }
    .close-request-popup {
      position: absolute;
      right: 20px;
      top: 47px;
      width: 22px;
      height: 22px;
      z-index: 999;
    }
    .close-request-popup:after,
    .close-request-popup:before {
      height: 23px;
    }
    .container {
      padding: 0 15px 15px 15px;
      height: 100%;
    }
  }

}
