.section-category-podcast {
  .podcast-title {
    padding-bottom: 18px;
    margin-bottom: 28px;
  }

  .sidebar-wrap {
    .search {
      //margin-top: 12px;
    }
  }

  .article-details {
    .d-flex {
      :first-child {
        font-weight: 700 !important;
        text-transform: uppercase;
      }

      .date {
        padding-left: 0;
      }
    }
  }
}

.article-podcast {
  background-color: @grey;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 55px;
  margin-top: 7px;

  .bg-image {
    position: absolute;
  }

  .description {
    img {
      display: none;
    }
  }

  .podcast-hero {
    position: relative;
    min-height: 400px;
    padding: 39px 44px 38px;
    color: #fff;

    h1 {
      margin-top: 0;
      font-size: 50px;
      position: relative;
      padding-bottom: 25px;
      margin-bottom: 28px;
      text-transform: uppercase;

      &::after {
        content: "";
        position: absolute;
        width: 60px;
        bottom: 0;
        left: 0;
        border-bottom: 6px solid #cafccc;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .podcast-hero-content {
    position: relative;
    z-index: 9;
    max-width: 510px;
  }

  .podcast-description {
    font-size: 18px;
    line-height: 22px;
  }

  .podcast-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    background-color: @blue;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;

    .desktop {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

    .mobile {
      //position: absolute;
      right: 0;
      transform: translateX(20px);
      max-width: 170px;
      margin-left: auto;
      width: 100%;
      align-self: flex-start;
    }

  }

  .podcast-details {
    padding: 36px 44px 45px;
    font-size: 18px;
    
    h3 {
      font-size: 30px;
      text-transform: initial;
      margin-top: 0;
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 17px;
    }

    a {
      text-decoration: underline;
    }

    .point {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .subscribe {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 17px;

    a {
      height: 43px;
      display: flex;
      flex-direction: row;
      padding: 9px 15px;
      border: 1px solid @blue;
      border-radius: 22px;
      margin-right: 10px;
      align-items: center;
      font-size: 14px;
      text-decoration: none;
      transition: 200ms;
      margin-bottom: 10px;

      img, svg {
        max-height: 100%;
        margin-right: 10px;
      }
      
      &:hover {
        background-color: @blue;
        color: #fff;
      }
    }
  }
}

.podcast-stats {
  padding-bottom: 40px;

  h2 {
    margin-bottom: 23px;
    padding-bottom: 22px;
  }

  .subtitle {
    //margin-bottom: 40px;
  }

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 10px;
    margin-top: 18px;
  }

  p {
    font-size: 18px;
    line-height: 22px;
    margin-top: 0;
  }

  .podcast-stat {
    p {
      max-width: 155px;
    }
  }
}

.about-podcast {
  padding-bottom: 40px;

  h2 {
    padding-bottom: 18px;
    margin-bottom: 23px;
  }

  p {
    font-size: 18px;
    max-width: 720px;
    margin-top: 0;
  }

  .mass-media {
    margin-bottom: 15px;
    padding-top: 18px;
    flex-wrap: wrap;
  }

  .logo-wrap {
    margin-right: 55px;
    //max-height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .podcast-services {
    overflow: auto;
    flex-wrap: nowrap;
    padding-bottom: 20px;
  }

  .podcast-service {
    text-align: center;
    min-width: 275px;
  }

  .podcast-services {
    font-size: 18px;
    img {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 991px) {

  .article-podcast {

    .podcast-hero {
      h1 {
        font-size: 32px;
      }
    }

    .subscribe {
      a {
        font-size: 0;
        background-color: transparent;
        padding: 0;
        border: none;
        border-radius: 0;
        width: auto;
        height: auto;
        margin-right: 25px;

        img, svg {
          margin-right: 0;
          width: 43px;
          height: 43px;
        }

        &:hover {
          background-color: transparent;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

  }

}

@media screen and (max-width: 767px) {
  .article-podcast {
    overflow: visible;
    margin-bottom: 8px;

    .podcast-hero {
      padding: 22px 25px 37px;
      min-height: initial;

      h1 {
        padding-bottom: 18px;

        &::after {
          width: 46px;
        }
      }
    }
    .podcast-description {
      p {
        font-size: 16px;
        line-height: 21px;

        &:first-of-type {
          max-width: calc(100% - 110px);
        }
      }
    }
    .podcast-details {
      padding: 19px 26px 18px;

      h3 {
        font-size: 26px;
        margin-bottom: 22px;
      }
    }
  }

  .podcast-stats {
    padding-bottom: 5px;
    h2 {
      margin-bottom: 13px;
    }
    h3 {
      font-size: 26px;
      margin-top: 15px;
    }
    p {
      font-size: 16px;
    }

    .subtitle p {
      margin-bottom: 10px;
    }

    .podcast-stat {
      p {
        margin-bottom: 0;
      }
    }
  }

  .about-podcast {
    padding-bottom: 0;

    h2 {
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .mass-media {
      margin-bottom: 25px;
    }

    .logo-wrap {
      max-width: 140px;
      margin-right: 15px;
      margin-bottom: 25px;

      &:nth-child(2) {
        margin-top: -10px;
        margin-right: 22px;
      }

      &:nth-child(3) {
        margin-right: 0;
      }

      img {
        max-width: 80%;
      }
    }
  }

}

@media screen and (max-width: 575px) {
  .article-podcast {
    .podcast-thumb {
      overflow: visible;
    }

    .subscribe {
      justify-content: space-between;
      a {
        margin-right: 0;
      }
    }

    .podcast-details {
      h3 {
        max-width: 200px;
      }
    }
  }
}