// Footer

footer {

  display: block;
  padding: 50px 0 70px 0;

  h3 {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: bold;

    a {
      text-decoration: none;
    }

  }

  p {
    margin: 0;
    font-size: 18px;
    line-height: 28.8px;
  }

  a {
    text-decoration: underline;
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 18px;
      line-height: 28.8px;
      color: @black;
    }
  }

  form {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    input {
      width: 100%;
      //max-width: 290px;
      margin-right: 10px;
      //margin-right: 10px;
      flex: 1;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .widget-wrapper {
    margin-bottom: 40px;

    h3 {
      margin-top: 0;
    }
  }

  .selected-text {
    margin-bottom: 34px;
  }

  .footer-logo {
    padding-bottom: 40px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% - 30px);
      left: 15px;
      border: 1px solid #e0e0e0;
      bottom: 0;
    }
  }

  .language {

    position: relative;

    img {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }

    & > a {

      display: flex;
      flex-direction: row;
      align-self: center;
      align-items: center;
      position: relative;
      padding: 10px 20px 10px 0;
      text-decoration: none;

      &:after{
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000;
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
      }

    }

    ul {
      position: absolute;
      min-width: 270px;
      padding: 25px 25px;
      list-style: none;
      top: 40px;
      right: -10px;
      border-radius: 5px;
      box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.1);
      background-color: @white;
      overflow: hidden;
      display: none;
      margin: 0;
      z-index: 10;
      li {
        line-height: 35px;
        display: block !important;
      }
    }

    &:hover {

      ul {
        display: block;
      }

      & > a {
        &:after{
          border-top: 5px solid @blue;
        }
      }

    }

  }

  .upwork-image {
    //text-align: right;
    img {
      //max-width: 162px;
      margin-top: 65px;
      max-width: 100%;
    }
  }

  .media-list {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    li {
      margin-right: 45px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: flex;
        flex-direction: row;
        align-self: center;
      }
      img, svg {
        max-width: 27px;
        max-height: 27px;
        width: auto;
        height: auto;
        margin-right: 15px;
      }
    }
  }

  .underline-dots {
    position: relative;
    text-decoration: none;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      left: 0;
      border-bottom: 1px #000 dotted;
    }
  }

  .copyright {
    margin-bottom: 10px;
  }

  .terms-wrap {
    a:first-child {
      margin-right: 70px;
      position: relative;
      &:after {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 90px;
        background-color: @black;
        position: absolute;
        right: -41px;
        top:6px
      }
    }
  }

  .sp-form-outer {
    max-width: 94%;
  }

}

@media screen and (max-width: 767px) {
  footer {
    .sp-form-outer {
      max-width: 100%;
    }
    .selected-text {
      margin-bottom: 14px;
    }
    .widget-wrapper {
      margin-bottom: 20px;
    }
    .upwork-image {
      text-align: left;
      img {
        margin-top: 35px;
      }
    }
    .media-list {
      flex-direction: column;
      margin-bottom: 0;
      li {
        margin-right: 0;
        margin-bottom: 10px;
        max-height: 29px;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  footer {
    .fix-selected-bg{
      &:after {
        width: 82%;
      }
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
    ul li {
      font-size: 14px;
    }
    .upwork-image {
      img {
        margin-top: 0px;
      }
    }
    .language {
      img {
        display: none;
      }
      &>a:after {
        top: 17px;
      }
    }
    .terms-wrap a{
      display: block;
      margin-bottom: 10px;
      &:first-child:after{
        display: none;
      }
    }
  }
}
