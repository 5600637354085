.section-hero {

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 67px;
    line-height: 1.15em;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: .67em !important;
    span {
      color: @blue;
    }
  }

  p {
    font-size: 21px;
    max-width: 500px;
    font-weight: 500;
    line-height: 1.3em;
  }

  .button {
    margin-top: 15px;
    display: inline-block;
    padding: 16px 35px;
  }

  .container {
    position: relative;
  }

  .row {
    position: relative;
    z-index: 1;
  }

  svg {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 48%;
    opacity: .5;
    z-index: 0;
    //animation: scales 4s ease-in infinite alternate;
  }

  @media screen and (min-width: 768px) {
    svg {
      animation: scales 4s ease-in infinite alternate;
    }
  }

  @keyframes scales {
    from {
      -webkit-transform: scale(0.95);
      -moz-transform: scale(0.95);
      transform: scale(0.95);
    }
    to {
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
      transform: scale(1.05);
    }
  }

}

@media screen and (max-width: 767px) {

  .section-hero {

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 20px;
    }

  }

}
