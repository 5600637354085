.section-key-services {

  overflow: hidden;

  h2 {
    font-size: 50px;
    font-family: 'Montserrat';
    line-height: 1.15;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-top: 0 !important;
    max-width: 540px;
    margin-bottom: 27px !important;
  }

  .box {
    border-radius: 16px;
    background-color: #f7f7f7;
    padding: 40px;
    margin-bottom: 30px;
    cursor: pointer;
    h3 {
      font-size: 30px;
      font-family: 'Montserrat';
      line-height: 1.15;
      font-weight: 700;
      margin-top: 0;
      text-decoration: underline !important;
    }
    a {
      display: block;
      width: max-content;
      margin-top: 35px;
      margin-bottom: -20px;
      transition: 1s;
    }
    &:hover {
      background-color: @blue;
      * {
        color: #fff !important;
        fill: #fff !important;
      }
      a {
        margin-left: 15px;
      }
    }
  }

  .container {
    position: relative;
    .row {
      position: relative;
      z-index: 1;
      & + svg {
        position: absolute;
        bottom: -30px;
        right: -15%;
        width: 300px;
        height: auto;
        z-index: 0;
        opacity: .05;
      }
    }

  }

}

@media screen and (max-width: 1199px){
  .section-key-services {
    h2 {
      margin-bottom: 40px !important;
    }
    .box {
      h3 {
        br {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .section-key-services {
    padding-top: 40px !important;
    padding-bottom: 45px !important;
    h2 {
      font-size: 30px;
    }
    .box {
      padding: 20px 20px 35px 20px;
      min-height: 225px;
      h3 {
        font-size: 21px;
      }
      p {
        font-size: 20px;
      }
      a {
        margin-top: 15px;
      }
    }
  }
}
