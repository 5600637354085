// Clients by services

.section-clients-by-services {

  h3 {
    font-size: 25px;
    margin-top: 0;
  }

  .mt {
    margin-top: 70px;

    h3 {
      font-family: 'Montserrat', sans-serif;
    }
  }

  h4 {
    font-size: 22px;
    margin: 15px 0;
    display: block;
    font-weight: 500;
    text-decoration: underline;
    span {
      color: rgba(0,0,0,.2);
      font-size: 17px;
      display: inline-block;
      margin-left: 5px;
    }
  }

  .row-industries {
    display: flex;

    &-toggler {
      position: relative;
      z-index: 3;
    }
  }

  .text-normal {
    display: block !important;
    font-size: initial !important;
    font-weight: normal !important;
    line-height: 34px;
    margin: 0 !important;
    color: #000000 !important;
  }

  .sub-title-wrap {
    p {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .direction-list {
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    line-height: 32px;
    padding-left: 0;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    li {
      margin-right: 30px;
      margin-bottom: 10px;

      &:first-child {
        font-weight: bold;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: underline;
      //display: inline-block;
      //border-bottom: 1px solid #000;

      span {
        padding-left: 10px;
        color: #979797;
      }
    }
  }

  .button-wrap {
    //align-self: start;
    //align-items: start;
    //justify-content: center;
    margin-top: 30px;
    display: flex;
    .button {
      color: @blue;
      border-color: @blue;
      background-color: transparent;
      display: flex;
      svg {
        margin-right: 15px;
      }
    }
  }

  .certifications-wrap {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    gap: 35px;
    grid-auto-rows: 100px;
    height: 100%;
    align-content: center;
    img {
      max-height: 100%;
    }

    @media screen and (min-width: 992px) {
      justify-content: end;
    }
  }

  .services-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    height: 100%;
    align-content: center;
    h3 {
      margin: 0;
      span {
       color: rgba(0,0,0,.2);
       display: inline-block;
       margin-left: 10px;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  ul {
    margin-top: 25px;
    padding-left: 0;

    &.list-style-none{
      list-style: none;
      margin-top: 15px;
    }

    li {
      font-size: 18px;
      //line-height: 1.4;
      line-height: 32px;
      //text-decoration: underline;
      padding-left: 5px;
      span {
        color: rgba(0,0,0,.2);
        //color: #000;
        display: inline-block;
        //display: inline;
        margin-left: 5px;
        font-size: 16px;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .clients-list {

    //padding-top: 172px;
    position: relative;

    & > div {
      margin-top: 40px;
      z-index: 1;
    }

    & > .size-4 {

      &:first-child {
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: 349px;
          height: 330px;
          background-image: url("../img/circle-v1.svg");
          background-repeat: no-repeat;
          background-size: contain;
          top: -54px;
          right: -139px;
          z-index: -1;
        }

        &:before {
          position: absolute;
          content: "";
          width: 707px;
          height: 679px;
          background-image: url("../img/circle-v2.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          left: -238px;
          bottom: -294px;
          z-index: -1;
        }
      }
    }

    .white-box {
      background: #fff;
      padding: 50px 35px;
      border-radius: 10px;
      font-size: 18px;
      height: 100%;

      .logo-wrap {
        align-self: center;
        //min-height: 76px;
        display: flex;
        align-items: center;
        height: 60px;

        img {
          width: 100%;
          height: auto;
          max-height: 76px;
          max-width: 180px;
        }
      }

      .client-info {
        p {
          font-size: 18px;
          line-height: 25px;

          a {
            display: block;
            width: max-content;
            color: #000;
            font-weight: 500;
            text-decoration: underline;
          }
        }
      }

      .tag-list {
        padding-top: 40px;
        padding-bottom: 50px;
        margin-left: -5px;
        margin-right: -5px;

        &.half-client-tag-list {
          padding-top: 27px;
          padding-bottom: 40px;
        }

        &.third-client-tag-list {
          padding-top: 20px;
          padding-bottom: 0;
        }

        a:not(.dropdown-toggle) {
          border-radius: 30px;
          border: solid 1px rgba(0, 0, 0, 0.2);
          display: inline-block;
          margin: 5px;
          padding: 8px 12px;
          color: #194f90;
          font-size: 14px;
          &:hover {
            background-color: #194f90;
            color: #fff;
          }

          &.secondary-tag {
            display: none;

            &.show {
              display: inline-block;
            }
          }
          @media screen and (min-width: 768px) {
            &.secondary-tag {
              display: inline-block !important;
            }
          }
        }
      }

      .details {

        & > *:first-child {
          margin-top: 0;
        }

        & > * {
          margin-bottom: 46px;
        }

        & > *:last-child {
          margin-bottom: 0;
        }

        .image-wrap {
          img {
            border-radius: 15px;
            //border: solid 1px #ccc;
            background-color: #ccc;
            padding: 1px;
          }
        }

        h3 {
          margin-top: 0;
        }

        .case-box {

          &:hover {
            background-color: #194f90;
            * {
              color: #fff;
              fill: #fff;
            }
          }

          h3 {
            margin-top: 0;
          }

          //padding: 30px 40px;
          border-radius: 16px;
          background-color: #f7f7f7;

          & + h3 {
            margin-top: 46px;

            & + ul {
              padding-left: 20px;
            }
          }

          p:last-child {
            margin-bottom: 0;
          }

          .button-case {
            //margin-top: 25px;
            //width: 34px;
            //height: auto;
            padding: 30px 40px;
            display: block;
            svg{
              margin-top: 20px;
              max-width: 100%;
              transition: .45s;
              transform: matrix3d(0);
            }
            &:hover {
              svg{
                transform: matrix3d(0);
                margin-left: 4px;
              }
            }
          }

        }

        .do_percentage {
          display: flex;
          justify-content: space-between;
          padding-left: 2px;
          padding-right: 2px;

          &_item {
            flex: 0 0 48%;
            width: 48%;
            span {
              font-family: Montserrat, sans-serif;
              font-size: 60px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #194f90;
            }

            a {
              text-decoration: underline;
            }

            br {
              display: none;
            }

            p {
              margin-bottom: 0;
            }

            .green {
              //color: #7bc87c;
            }
          }
        }

        .feedback-wrap {

          .show-feedbacks {
            margin-left: 112px;
            font-weight: bold;
            text-decoration: underline;
            position: relative;
            color: #194f90;
            padding-right: 25px;

            &:focus, &:active {
              box-shadow: none;
              border: none;
              outline: none;
            }

            &::after {
              position: absolute;
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              border-bottom: 2px solid #194f90;
              border-right: 2px solid #194f90;
              transform: rotate(45deg);
              right: 0;
              top: 4px;
              transition: .2s;
            }
          }

          .show-feedbacks--marg {
            margin-left: 112px;
          }

          &.show-all {
            .feedback {
              &-hidden {
                height: auto;
                padding-bottom: 50px;
                transform: rotate3d(1,0,0,0deg);
                opacity: 1;
              }
            }
            .show-feedbacks {
              &::after {
                transform: rotate(225deg);
                top: 8px;
              }
            }
          }
        }

        .feedback {
          padding-bottom: 50px;

          &-hidden {
            transform: rotate3d(1,0,0,90deg);
            height: 0;
            opacity: 0;
            transition: .2s;
            padding-bottom: 0;
          }
        }

        .feedback,
        .podcast {
          flex-direction: row;
          align-items: normal;
          align-self: center;
          display: flex;

          img {
            margin-right: 29px;
            max-height: 68px;
            min-height: 68px;
            min-width: 68px;
            width: auto;
            border-radius: 90px;
          }

          picture {
            margin-right: 29px;
            max-height: 68px;
            min-height: 68px;
            min-width: 68px;
            width: auto;
            border-radius: 90px;

            img {
              margin-right: 0;
            }
          }

          p {
            margin: 0;
            font-size: 18px;
            align-self: center;
          }

          a {
            text-decoration: underline;
            justify-content: center;
            display: flex;
          }

          strong {
            display: block;
            font-weight: 700;
            font-size: 16px;
            margin-top: 20px;
          }

          span {
            display: block;

            font-size: 16px;
          }
        }

        .feedback {
          a {
            justify-content: flex-start;
          }

          &__author {
            font-weight: bold;
            margin-top: 20px;
          }

          &__author-position {

          }
        }

        .job-list {
          ul {
            padding-left: 25px;
            list-style: none;
            li {
              position: relative;
              span {
                color: #000;
                margin-left: 0;
                font-size: 16px;
                line-height: inherit;
                display: inline-block;
                a {
                  font-weight: 600;
                }
              }
              &:before{
                content: "\2022";
                color: #194f90;
                font-weight: 700;
                display: inline-block;
                width: 15px;
                margin-left: -20px;
                font-size: 24px;
                position: absolute;
                left: 0;
                height: 100%;
                line-height: 1.2;
              }
            }
          }
        }

        .percent-box {

        }

      }
    }

    .size-1 {

      .white-box {

        img {
          width: auto;
          height: auto;
          max-width: 120px;
          max-height: 60px;
        }

        .tag-list {
          padding-top: 0;
        }

      }

    }

  }

  .size-1 {
    .client-info .company-name {
      display: inline !important;
    }

    .client-info {

      p {
        margin-top: 20px;
        margin-bottom: 20px;
      }

    }

    .dropdown {

      &-toggle {
        font-weight: 700;
        text-decoration: underline;
        position: relative;
        color: #194f90;
        padding-right: 15px;
        font-size: 14px !important;
        margin-left: 5px !important;
        margin-top: 10px !important;
        display: inline-block;

        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-bottom: 2px solid #194f90;
          border-right: 2px solid #194f90;
          transform: rotate(45deg);
          right: 0;
          top: 4px;
          transition: .2s;
        }

        &.open {
          &::after {
              transform: rotate(225deg);
              top: 8px;
          }
        }
      }

      @media screen and (min-width: 768px) {
        &-toggle {
          display: none !important;
        }
      }
    }

    .white-box {
      .logo-wrap {
        height: 30px;
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: 575px) {
          height: 30px;
          img {
            max-height: 30px !important;
          }
        }

        @media screen and (min-width: 768px) {
          height: 60px;
          img {
            max-height: 60px;
          }
        }
      }
    }
  }

  .do_percentage_item {
    .green {
      color: #7bc87c !important;
    }

    & > span {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }

  .list-industries {
    ul {
      margin: 0 !important;
    }

    .extra-text {
      a {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .section-clients-by-services .clients-list .white-box .details .do_percentage_item span {
    font-size: 40px;
  }

}

@media screen and (min-width: 991px) {
  .section-clients-by-services .clients-list .white-box .details .do_percentage_item span {
    min-height: 79px;
  }
}

@media screen and (max-width: 991px) {

  .section-clients-by-services .clients-list .white-box .details .do_percentage_item span {
    font-size: 30px;
  }

  .section-clients-by-services{
    .clients-list {
      padding-top: 0;
      &:after {
        opacity: .5;
      }

      .white-box {
        .client-info {
          p {
            margin-top: 15px;
          }
        }
        .details {
          margin-top: 50px;
        }
        .tag-list {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

}

@media screen and (max-width: 575px) {

  .section-clients-by-services {

    h2 {
      margin-top: 15px !important;
      padding-bottom: 15px !important;
    }

    .sub-title-wrap {
      p {
        font-size: 18px;
        margin: 15px 0 15px 0;
      }
    }

    h3 {
      font-size: 28px;
      margin-top: 25px;
      margin-bottom: 7px;

      a {
        font-size: 16px;
      }
    }

    h4 {
      font-size: 18px;
      margin: 10px 0;
    }

    .mt {
      margin-top: 30px;
    }

    .list-industries {
      display: none;

      &-toggler {
        display: block;
        position: relative;
        z-index: 3;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 6px);
          right: 0;
          width: 25px;
          height: 12px;
          background-image: url("data:image/svg+xml,%3Csvg width='27' height='16' viewBox='0 0 27 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.2008 1.29999L13.5008 14L0.800781 1.29999' stroke='%231D1D1B' stroke-width='3' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          transition: .2s;
        }

        &.active {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    ul {
      margin-top: 15px;
      li {
        font-size: 16px;
        padding-left: 0;
        line-height: 28px;
      }
    }

    .services-wrap {
      flex-direction: column;
      gap: 5px;
      align-content: stretch;
    }

    .clients-list {

      .white-box {
        padding: 20px 15px;
        margin-top: 20px;
        font-size: 16px;

        .logo-wrap {

          img {
            max-height: 60px;
            max-width: 110px;
          }

        }

        .tag-list {
          padding-top: 0;
          padding-bottom: 20px;

          a {
            font-size: 12px;
            margin: 0;
            margin-right: 3px;
            margin-bottom: 7px;
          }
        }

        p {
          font-size: 16px;
        }

        .client-info p {
          //font-size: 14px;
          line-height: 21px;
        }

        .details {
          margin-top: 30px;

          & > * {
            margin-bottom: 20px;
          }

          .case-box .button-case {
            padding: 30px;
          }

          .job-list ul {
            margin: 0;
            li {
              &:before {
                margin-left: -15px;
                font-size: 20px;
                line-height: 1;
              }
              span {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }

          .feedback,
          .podcast {
            p {
              margin: 0;
              font-size: 16px;
            }
          }

          .feedback {
            padding-bottom: 20px;
            img {
              margin-right: 0;
            }
            picture {
              margin-right: 0;
              img {
                margin-right: 0;
              }
            }
          }

          .do_percentage_item {
            br{
              display: none;
            }
            p{
              margin: 0;
            }
          }

          .feedback-wrap .show-feedbacks,
          .feedback-wrap .show-feedbacks--marg {
            margin-left: 82px;
            margin-top: 5px;
            display: inline-block;
          }

          .feedback-hidden {
            display: none !important;
          }

          .feedback-wrap.show-all .feedback-hidden {
            display: flex !important;
            padding-bottom: 20px !important;
          }

        }
      }
    }
  }
}
