.legal {
  .header {
    background: transparent;
  }
}

@media screen and (min-width: 992px) {
  .legal-olga,
  .legalru,
  .legal {
    h1 {
      font-size: 45px !important;
    }
  }
}
@media screen and (min-width: 1200px) {
  .legal {
    .logo {
      //padding-left: 50px;
      //width: 358px;
    }
  }
}