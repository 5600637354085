.review-box {
  background-color: #f7f7f7;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 30px;

  .review-video-wrap {
    position: relative;
    color: #fff;
    overflow: hidden;
    height: 100%;
    min-height: 430px;
  }

  .review-thumb {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    .hovered-image {
      opacity: 0;
      transition: 200ms;
    }

    .filtered-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: right bottom;
    }
  }

  &:hover {
    .hovered-image {
      opacity: 1;
    }
  }

  .review-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 33px 37px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 3;

    .play {
      width: 110px;
      height: 110px;
      opacity: .75;
      transition: 50ms;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../img/icons/play-hover.png);
      background-size: contain;
    }

    .review-author {
      margin-bottom: 0;
      font-size: 18px;
    }
  }

  .review-company {
    position: relative;
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 20px;
    margin-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #cafccc;
      width: 50px;
      height: 4px;
    }
  }

  .review-info {
    padding: 40px 50px 47px 27px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    h3 {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      margin-bottom: 25px;
      font-size: 27px;
      line-height: 32px;
      margin-top: 0;
    }

    h4 {
      font-family: 'Roboto Medium', sans-serif;
      font-size: 25px;
      margin-top: 0;
      line-height: 32px;
    }

    .button {
      margin-top: auto;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 18px;
      padding: 13px 28px;
    }

    .logo-wrap {
      height: 48px;
      margin-bottom: 24px;
    }
  }

  .review-logo {
    max-width: 195px;
    max-height: 48px;
    //margin-bottom: 24px;
    //width: auto;
    height: auto;
    //width: 100%;
  }

  .review-author {
    margin-bottom: 20px;

    .name {
      font-size: 21px;
      line-height: 27px;
      font-family: 'Roboto Medium', sans-serif;
    }

    & > * {
      display: inline-block;
    }

    .facebook-link {
      width: 15px;
      height: 15px;
      margin-left: 1px;
      margin-right: 14px;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .position {
      color: rgba(0,0,0,.6);
      font-size: 18px;
      display: block;
      a {
        text-decoration: underline;
        color: inherit;
      }
    }
  }

  .review-author-thumb {
    position: relative;
    width: 121px;
    height: 121px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 30px;

    img {
      object-fit: cover;
      object-position: center;
    }
  }

  &:hover {
    cursor: initial;

    .button {
      color: #fff;

      &:hover {
        color: #194f90;
      }
    }
  }

  &-type-1 {
    .filtered-image {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      overflow: hidden;
    }
    .review-overlay {
      &:hover {
        cursor: pointer;
        color: #fff;
      }
    }
    &:hover {
      .play {
        opacity: 1;
      }
    }
  }

  &-type-2 {
    height: calc(100% - 30px);

    .review-video-wrap {
      min-height: initial;
    }

    .review-thumb {
      position: static;
    }

    .review-author {
      .name {
        margin-bottom: 10px;
      }
      .position {
        display: block;
      }
    }

    .review-overlay {
      padding-bottom: 52px;
    }

    .review-info {
      padding-right: 37px;
      padding-left: 37px;
    }

  }

  &-type-3 {
    height: calc(100% - 30px);

    .logo-wrap {
      height: 45px;
    }

    .review-logo {
      max-width: 160px;
      max-height: 45px;
    }

    .review-info {
      min-height: 660px;
      padding: 40px 35px 45px 35px;
    }

    .review-author {
      margin-bottom: 25px;
    }

    .review-author-thumb {
      position: relative;

      .filtered-image {
        filter: grayscale(100%);
        width: 100%;
        height: 100%;
      }

      .hover-image {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: 200ms;
      }
    }

    &:hover {
      .review-author-thumb {
        .hover-image {
          opacity: 1;
          z-index: 3;
        }
      }
    }

  }

  @media screen and (max-width: 1200px) {
    &-type-1 {
      .review-video-wrap {
        min-height: initial;
        padding-bottom: 330px;
      }
    }

    .review-overlay {
      padding: 15px;

      .play {
        width: 50px;
        height: 50px;
      }

      .review-author {
        font-size: 16px;
      }
    }

    .review-company {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 10px;
      margin-bottom: 8px;

      &::after {
        height: 2px;
      }
    }

    .review-info {
      padding: 20px;

      h3, h4 {
        font-size: 18px;
        line-height: 24px;
        font-family: "Roboto Medium", sans-serif;
      }

      .button {
        font-size: 14px;
      }
    }

    //.review-logo {
      //margin-bottom: 25px;
    //}

    .review-author {
      .name {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 5px;
      }
    }

    &-type-3 {
      .review-info {
        min-height: initial;
      }
    }


  }
  @media screen and (max-width: 991px) {
    .review-thumb {
      display: block;
    }

    &-type-1 {
      .filtered-image {
        border-top-right-radius: 16px;
        border-bottom-left-radius: 0;
      }
    }
  }
  @media screen and (max-width: 560px) {
    &-type-1 {
      .review-video-wrap {
        padding-bottom: 300px;
      }
    }
  }
  @media screen and (max-width: 470px) {
    &-type-1 {
      .review-video-wrap {
        padding-bottom: 270px;
      }
    }
  }
  @media screen and (max-width: 370px) {
    &-type-1 {
      .review-video-wrap {
        padding-bottom: 250px;
      }
    }
  }
}

.review-overlay {
  .review-company {
    color: #fff !important;
  }
  .review-author {
    color: #fff !important;
  }
}