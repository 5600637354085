// News

.section-news {

  .button-wrap {
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .news-list {
    margin-top: 75px;
  }

  .news-item {
    margin-bottom: 50px;
    .logo-wrap {
      height: 70px;
      margin-bottom: 30px;
      * {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
    a{
      font-size: 18px;
      text-decoration: underline;
    }
  }

  .button-wrap{
    margin-top: 50px;
    a{
      position: relative;
      padding-right: 55px;
      padding-left: 40px;
      span{
        position: absolute;
        right: 27px;
        height: 100%;
        top: 8px;
        font-size: 14px;
      }
    }
  }

}

@media screen and (max-width: 991px) {
  .section-news .news-list {
    margin-top: 35px;
  }
  .section-news .button-wrap {
    margin-top: 10px;
  }
}

@media screen and (max-width: 576px) {
  .section-news {
    .news-list {
      margin-top: 10px;
    }
    .news-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      .logo-wrap {
        height: auto;
        margin-bottom: 15px;
        flex: 0 0 70px;
        max-width: 70px;
        margin-right: 20px;
      }
      a {
        font-size: 14px;
      }
    }
  }
  .section-news .button-wrap a {
    width: 100%;
    text-align: center;
    span {
      //right: 32px;
      top: 10px;
      font-size: 10px;
      right: 50%;
      margin-right: -90px;
    }
  }
}
