.section-database {

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 67px;
    line-height: 1.15em;
    text-transform: uppercase;
  }

  p {
    font-size: 21px;
    max-width: 770px;
    line-height: 1.3em;
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 10px;
  }
  h3 + p {
    margin-top: 0;
    font-size: 18px;
  }

  .button {
    margin-top: 15px;
    display: inline-block;
  }

}

@media screen and (max-width: 767px) {

  .section-database {

    padding-top: 36px !important;
    padding-bottom: 40px !important;

    h2 {
      font-size: 32px;
      padding-bottom: 13px !important;
      margin-bottom: 0 !important;
    }

    p {
      font-size: 20px;
      line-height: 23.44px;
    }

    .database-stat {
      p {
        font-size: 16px;
      }
    }

  }

}
