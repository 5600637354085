.section-smi {
  .breadcrumb.v2 {
    margin: 0;
  }
  .h2 {
    margin-bottom: 40px;
    margin-top: 25px;
  }
  .smi-list {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 20px;
    &:not(:last-child):after {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 1px;
      background: #e0e0e1;
    }
    .smi-item {
      //margin-bottom: 30px;
      &:not(:last-child) {
        border-right: 1px solid #e0e0e1;
      }
      .item-logo {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        height: 150px;
        img {
          max-width: 100%;
          max-height: 150px;
          width: initial;
        }
      }
      .item-content {
        height: 91px;
        & + div {
          a {
            display: inline;
            border-bottom: 1.3px solid rgba(0,0,0,.2);
            &:hover {
              color: #194f90;
              border-bottom: 1px solid #194f90;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 767px){

  .section-smi {

    .smi-list {
      margin-bottom: 0;
      padding-bottom: 0;
      .smi-item {
        border-right: 0 !important;
        border-bottom: 1px solid #e0e0e1;
        margin-bottom: 15px;
        padding-bottom: 15px;

        .item-logo {
          height: initial !important;
          margin-top: 20px;
        }

        .item-content {
          height: initial;
        }
      }
      &:after {
        display: none !important;
      }
    }

  }

}
