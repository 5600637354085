.section-unsupported-directions {
  background-color: @grey;

  h3 {
    font-size: 30px;
  }
}

.unsupported-directions {
  list-style: none;
  margin: 0 -45px;
  padding: 0;
  display: flex;
  flex-flow: row wrap;

  li {
    position: relative;
    display: inline-block;
    margin-left: 0;
    margin-bottom: 15px;
    padding-left: 45px;
    padding-right: 45px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      height: 4px;
      top: calc(50% - 2px);
      left: -2px;
      background-color: #194F90;
      border-radius: 50%;
    }

    &.first-item {
      &::before {
        display: none;
      }
    }
  }

  @media screen and (max-width: 575px) {
    margin: 0 -15px;

    li {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}