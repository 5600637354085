// Header

.header {

    height: 125px;
    display: block;
    position: relative;
    z-index: 99 !important;
    //background: @grey;

    .container {

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        align-items: center;

    }

    .nav {

        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        @media (min-width: 1200px) {
            flex: 1 1 auto;
            max-width: calc(100% - 300px);

            li:nth-child(1) {
                margin-left: auto !important;
            }

            li.menu-item-language-switcher {
                margin-left: auto !important;
            }
        }

        .sub-menu {
            position: absolute;
            min-width: 170px;
            //padding: 35px 17px;
            padding: 7px 12px;
            list-style: none;
            top: 40px;
            //right: -10px;
            //right: -24px;
            left: -12px;
            border-radius: 5px;
            box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.1);
            background-color: @white;
            overflow: hidden;
            display: none;
            margin: 0;

            .menu-item {
                margin-left: 0;
                margin-right: 0;
                padding: 6.5px 0;

                a {
                    font-weight: 500;
                }
            }

        }

        .menu-item {

            margin: 0 15px;
            padding: 10px 0;
            position: relative;
            cursor: pointer;

            a {
                font-size: 18px;
                text-decoration: underline;
                font-weight: 500;
                white-space: nowrap;
            }

            &:hover > a {
                color: @blue;
            }

            &-language-switcher {
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.1598 10.5001C20.1598 15.8352 15.8349 20.1601 10.4998 20.1601M20.1598 10.5001C20.1598 5.16502 15.8349 0.840088 10.4998 0.840088M20.1598 10.5001H0.839844M10.4998 20.1601C5.16477 20.1601 0.839844 15.8352 0.839844 10.5001M10.4998 20.1601C13.7099 20.1601 16.3122 15.8352 16.3122 10.5001C16.3122 5.16502 13.7099 0.840088 10.4998 0.840088M10.4998 20.1601C7.28976 20.1601 4.68747 15.8352 4.68747 10.5001C4.68747 5.16502 7.28976 0.840088 10.4998 0.840088M10.4998 20.1601C11.5849 20.1601 12.4646 15.8352 12.4646 10.5001C12.4646 5.16502 11.5849 0.840088 10.4998 0.840088M10.4998 20.1601C9.41474 20.1601 8.5351 15.8352 8.5351 10.5001C8.5351 5.16502 9.41474 0.840088 10.4998 0.840088M0.839844 10.5001C0.839844 5.16502 5.16477 0.840088 10.4998 0.840088M3.29578 4.11466H17.7448M3.25484 16.8855H17.7448' stroke='%239E9E9E' stroke-width='0.736'/%3E%3C/svg%3E%0A");
                background-size: 1.25rem;
                background-position: left center;
                background-repeat: no-repeat;
                position: relative;
                cursor: pointer;
                font-weight: bold;

                .sub-menu {
                    left: unset;
                    right: 0;
                    min-width: unset;
                }
            }

            &.menu-item-has-children {

                position: relative;
                padding: 10px 20px 10px 0 !important;
                z-index: 10;

                &.menu-item-language-switcher {
                    padding-left: 1.65rem !important;
                    padding-right: .85rem !important;
                    margin-right: 0;
                }

                &::after {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid @black;
                    content: "";
                    position: absolute;
                    top: 19px;
                    right: 0;
                }

                &:hover {

                    .sub-menu {
                        display: block;
                    }

                    &::after {
                        border-top: 5px solid @blue;
                    }

                }

            }

            &.menu-button {

                margin-right: 0;

                .button {
                    text-decoration: none;
                    color: #194f90;
                    background-color: transparent;
                }

                &:hover {
                    a {
                        color: #fff;
                        background-color: @blue;
                    }
                }

            }

        }

    }

    & + .section, + main > .section:first-child {
        padding-top: 30px;
    }

    &.white {
        background-color: #fff !important;
    }

    &.gray {
        background-color: #f7f7f7 !important;
    }

}

.hamburger-box {
    //position: relative;
    display: inline-block;
    width: 40px;
    height: 28px;
    border: 0;
    background-color: transparent;
    padding: 0;

    position: absolute !important;
    top: 41px;
    right: 15px;
    z-index: 101;

    &:focus, &:active {
        border: 0;
        outline: 0;
    }

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #B3B3B3 !important;
        //background-color: @blue;
    }

    .hamburger-inner {
        top: 2px;
    }

    .hamburger-inner:before {
        top: 10px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform, opacity;
    }

    .hamburger-inner:after {
        top: 20px;
        bottom: -10px;
    }

    .hamburger-inner:after, .hamburger-inner:before {
        display: block;
        content: "";
    }

}

.single-post .header {
    background: #fff;
}

.single-post .header {
    background: #fff;
}

.white-header .header {
    background: #fff;
}

.single-post .header {
    background-color: #f7f7f7 !important;
}

.mobile-menu {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;

    &-container {
        width: 100%;
        max-width: 300px;
        background-color: #fff;
        min-height: 100%;
        padding: 25px 15px;
        box-sizing: border-box;
        max-height: 100%;
        overflow-y: auto;
    }

    &-logo {
        max-width: 210px;

        a {
            display: block;
            position: relative;

            span {
                position: absolute;
                bottom: -3px;
                right: 0;
                font-size: 8px;
                //font-size: 7.7px;
                //font-weight: bold;
                font-weight: 500;
            }
        }
    }

    &-language {
        padding-top: 25px;

        ul {

            a {
                span {
                    text-decoration: underline;
                }

                &:hover,
                &:active,
                &:focus {
                    color: #000;
                }
            }

        }

        .mobile-menu-switcher {
            display: flex;
            align-items: center;
            margin-bottom: 7px;

            &::after {
                content: '';
                display: inline-block;
                border-top: 5px solid #000;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                margin-left: 10px;
                transition: .4s;
            }

            &.active {

                &::after {
                    transform: rotate(180deg);
                }

            }
        }

        img {
            display: block;
            width: 25px;
            margin-right: 7px;
        }

        .wpml-floating-language-switcher {
            display: none;
            background-color: #f5f5f5;
        }

        .wpml-ls-legacy-list-horizontal {
            padding: 10px 5px;

            a {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        .wpml-ls-current-language {
            display: none !important;
        }

        ul {
            display: flex;
            flex-direction: column;
        }
    }

    &-items {
        margin-top: 30px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            .menu-item {
                font-size: 26px;
                font-weight: bold;
                font-family: 'Montserrat', sans-serif;
                text-decoration: underline;
                margin-bottom: 10px;

                &-has-children {
                    margin-bottom: 20px;

                    & > a {
                        display: inline-block;
                        margin-bottom: 10px;
                        text-decoration: underline;
                    }
                }

                &.no-underline {
                    text-decoration: none;
                }

                &.no-hover {
                    a:hover {
                        color: initial;
                    }
                }

                &.why-no-phone {
                    //text-decoration-style: dashed;
                    text-decoration-style: initial;

                    a {
                        //border-bottom: 1px dashed #000;
                        //line-height: 0.85;
                        line-height: 1;
                        display: inline-block;
                        position: relative;
                        cursor: pointer;

                        //&:hover {
                        //  border-color: @blue;
                        //  cursor: pointer;
                        //}
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 1px;
                            left: 0;
                            border-bottom: 1px #000 dotted;
                        }
                    }
                }

                &-has-children.no-underline {
                    text-decoration: none !important;

                    & > a {
                        text-decoration: none !important;
                    }
                }

                .sub-menu {
                    .menu-item {
                        font-size: 16px;
                        font-family: Roboto, sans-serif;
                        font-weight: normal;
                    }
                }
            }
        }
    }

    &-why-no-phone {
        background-color: #F5F5F5;
        padding: 15px;
        margin: 15px 0;
        display: none;

        & > *:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    &-button {
        margin: 50px 0;

        .button {
            padding: 16px 52px;
        }
    }

    &-close {
        position: absolute;
        top: 31px;
        right: 20px;
        width: 35px;
        height: 35px;
    }

    .li-button {
        text-decoration: none !important;
        margin: 40px 0 !important;

        a {
            padding: 16px 52px;
            border-radius: 30px;
            border: solid 3px #194f90;
            text-decoration: none;
            color: #fff;
            background-color: #194f90;
            font-weight: 500;
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            margin-left: -3px;

            &:hover {
                &:hover {
                    color: @blue;
                    background-color: transparent;
                }
            }
        }
    }
}

.language-changer {

    &-bar {
        position: relative;
        width: 100%;
        background: #194F90;
        left: 0;
        top: 0;
        text-align: center;
        padding: 10px 50px;
        color: white;
        transition: 400ms;
        z-index: 998;

        p {
            margin: 0;
        }

        a {
            text-decoration: underline;
            transition: 200ms;

            &:hover {
                color: @grey;
                opacity: .75;
            }
        }

        .btn-close {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            border: none;
            background-color: transparent;
            padding: 0;
            cursor: pointer;

            svg {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        @media screen and (max-width: 991px) {
            text-align: left;
            padding: 8px 50px 8px 15px;

            a {
                display: block;
            }
        }

        &.close {
            transform: translate(35vw, 100vh) scale(.2);
        }
    }
}

@media screen and (min-width: 1201px) {
    .header {

        nav {
            display: flex !important;
        }

        .menu-item {
            //&.submenu-align-right {
            //    .sub-menu {
            //        right: -18px;
            //
            //        .menu-item {
            //            text-align: right;
            //        }
            //    }
            //}
        }

    }

    .mobile-menu {
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {

    .header {
        position: relative;
        height: initial;
        padding: 0;

        .container {
            max-width: 100%;
        }

        .nav {
            display: none;
            top: 125px;
            background-color: #194f90;
            z-index: 9;
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-right: -15px;
            padding: 0 15px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: visible;
            padding-top: 5px;
            //min-height: calc(100vh - 60px);
            //height: calc(100vh - 60px);

            .menu-item {
                margin: 10px auto 10px auto !important;
                width: 100%;
                border-bottom: 1px solid #fff;
                padding-bottom: 15px !important;

                &:hover > a {
                    color: #fff;
                }

                a {
                    text-decoration: none;
                    color: #fff;
                }

                &:last-child {
                    border-bottom: 0;
                }

                &.menu-item-has-children {
                    padding-bottom: 20px !important;

                    &::after {
                        border-top: 5px solid #fff !important;
                    }
                }

                &.menu-button {
                    margin: 25px 0 !important;
                    text-align: center;

                    .button {
                        display: inline-block;
                        text-align: center;
                        max-width: 400px;
                        color: #fff;
                        border: solid 2px #fff;
                    }
                }

                .sub-menu {
                    background-color: transparent;
                }
            }
        }

        &.open-menu {
            .hamburger-box {

                .hamburger-inner {
                    transform: translate3d(0, 10px, 0) rotate(45deg);
                    background-color: @grey !important;
                }

                .hamburger-inner:before {
                    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
                    opacity: 0;
                    background-color: @grey !important;
                }

                .hamburger-inner:after {
                    transform: translate3d(0, -20px, 0) rotate(-90deg);
                    background-color: @grey !important;
                }

            }

            .nav {
                .menu-item.menu-item-has-children {
                    &:hover .sub-menu {
                        display: none;
                    }

                    .mobile-sub-btn {
                        position: absolute;
                        z-index: 11;
                        right: -10px;
                        top: 6px;
                        height: 30px;
                        width: 30px;
                    }
                }

                .sub-menu {
                    width: calc(100% + 20px);
                    margin-left: 0;
                    position: static;
                    margin-top: 15px;
                    padding: 0 20px;
                    box-shadow: none;

                    .menu-item {
                        margin: 0 !important;
                        width: 100%;
                        padding-top: 15px;
                    }
                }
            }
        }

    }

}

@media screen and (max-width: 767px) {

    .header {
        .hamburger-box {
            width: 40px;
            padding: 0;
            top: 36px;
        }
    }

}

@media screen and (max-width: 575px) {

    .header {

        .nav {

            .menu-item a {
                font-size: 14px;
            }

            .menu-item.menu-button {
                margin: 10px 0 25px 0 !important;
            }

            .sub-menu {

                .menu-item {

                    border-bottom: 1px solid rgba(255, 255, 255, .1);

                    &:last-child {
                        border-bottom: none;
                    }

                }

            }

        }

        .hamburger-box {
            width: 46px;
            padding: 0;

            .hamburger-inner,
            .hamburger-inner:before,
            .hamburger-inner:after {
                width: 46px;
                height: 3px;
            }
        }
    }

}

@media screen and (max-width: 1200px) {

    .header.no-drop-down {
        .nav .menu-item.menu-item-has-children {
            padding: 10px 20px 10px 0 !important;
            padding-bottom: 0 !important;

            .sub-menu {
                display: block !important;
                //padding: 0!important;
                height: initial !important;
                margin-top: 15px !important;
                width: calc(100% + 20px) !important;
                position: static !important;
                border-radius: 0 !important;

                .menu-item {
                    margin: 0 !important;
                    width: 100% !important;
                    padding-top: 15px !important;
                    border-bottom: 1px solid rgba(255, 255, 255, 1);

                    &:first-child {
                        border-top: 1px solid #fff;
                    }

                    &:last-child {
                        border-bottom: 0 !important;
                    }
                }
            }
        }

        .nav .menu-item.menu-item-has-children::after {
            display: none !important;
        }
    }
}
