// Testimonials

.section-testimonials {

  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  &:after{
    position: absolute;
    content: "";
    background-image: url("../img/testimonials/group.svg");
    width: 700px;
    height: 542px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 70px;
    right: -15px;
    z-index: 0;
  }

  .testimonial-wrap {

    .button {
      border: solid 3px #194f90;
      background-color: transparent;
      color: #194f90;
      position: relative;
      padding-right: 55px;
      padding-left: 40px;
      white-space: nowrap;

      span {
        position: absolute;
        right: 32px;
        height: 100%;
        top: 10px;
        font-size: 14px;
      }

      &:hover {
        background-color: #194f90 !important;
        color: #fff !important;
      }
    }

    .testimonial-text {
      font-size: 35px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;

      p:first-child {
        margin-top: 44.5px;
      }
    }

    .testimonial-info {
      display: flex;
      margin-top: 170px;

      .feedback-wrap {

        flex: 0 0 70%;

        .feedback {

          flex-direction: row;
          align-self: center;
          display: flex;
          align-items: center;

          img {
            margin-right: 29px;
            max-height: 68px;
            min-height: 68px;
            min-width: 68px;
            width: auto;
            border-radius: 90px;
          }

          p {
            line-height: 25px;
          }

        }

      }

      .testimonial-btn-wrap {

        flex: 0 0 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

      }

    }

  }

}

@media screen and (max-width: 960px) {

  .section-testimonials {
    &:after{
      width: 500px;
      height: 542px;
      top: 70px;
      right: -15px;
    }
    h2 {
      br {
        display: none;
      }
    }
    .testimonial-wrap {
      .testimonial-info {
        flex-direction: column;
        margin-top: 100px;
        .feedback-wrap,
        .testimonial-btn-wrap{
          flex: 0 0 100%;
        }
        .testimonial-btn-wrap {
          margin-top: 30px;
          justify-content: center;
        }
      }
    }
  }

}

@media screen and (max-width: 575px) {

  .section-testimonials {

    padding-bottom: 30px;

    &:after{
      width: 400px;
      height: 542px;
      top: 0;
    }
    .testimonial-wrap {
      .testimonial-text {
        p {
          &:first-child {
            margin-top: 0;
          }
          font-size: 20px;
          margin-top: 24px;
        }
      }
      .testimonial-info {
        margin-top: 22px;
        .testimonial-btn-wrap {
          margin-top: 10px;
        }
      }
      .button {
        width: 100%;
        text-align: center;
        span {
          right: 50%;
          margin-right: -52px;
        }
      }
    }
  }

}
