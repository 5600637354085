.section-knowledge-base {

  h1 {
    margin-top: 25px;
    margin-bottom: 40px;
    font-size: 50px;
    position: relative;
    padding-bottom: 25px;
    &:after {
      content: "";
      position: absolute;
      width: 60px;
      bottom: 0;
      left: 0;
      border-bottom: 6px solid #194f90;
    }

    + .description {
      margin-top: -15px;
      margin-bottom: 30px;
      a {
        text-decoration: underline;
      }
      picture, img {
        display: none;
      }
    }

  }

  .breadcrumb {
    margin: 0;
  }

  .filtered-image {

      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-blend-mode: normal, color, normal;
      background-position: center !important;

    & + .hover-image {
      position: absolute;
      top:0;
      left:0;
      opacity: 0;
      transition: .2s;
      z-index: 2;
    }

  }

  a:hover {
    border-color: @blue !important;
  }

  article {
    overflow: hidden;
    border-radius: 16px;
    background-color: @grey;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;

    .article-link {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .article-thumbnail {
      width: 100%;
      height: 480px;
      a {

        display: block;
        position: relative;

        &:after{
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top:0;
          z-index:9;
          background-color: #157cf9;
          opacity: .4;
          //content: "";
        }

      }
      * {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .article-details {
      padding: 30px 35px 45px 35px;
      .category {
        font-weight: bold;
      }
      .date {
        color: rgba(0, 0, 0, 0.6);
        padding: 3px 5px;
      }
      h2 {
        text-transform: none;
        margin-top: 14px;
        margin-bottom: 18px;
        padding-bottom: 0;
        display: block;
        line-height: 1.4;
        //text-decoration: underline;
        &:after{
          display: none;
        }
        a {
          //border-bottom: 2px solid #000;
          border-bottom: 1.3px solid rgba(0,0,0,.2);
        }
      }
      p {
        display: block;
      }
      .d-flex {
        *:first-child {
          margin-right: auto;
          font-weight: normal;
        }
      }
      &>*:first-child {
        margin-top: 0;
      }
      &>*:last-child {
        margin-bottom: 0;
      }
    }

    h2 {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.17;
      color: #000000;
    }
    
    &:hover {
      .article-details {
        p a {
          color: inherit;
        }
      }
    }

  }

  .selected {
    &-posts {
      margin: 60px 0;

      &_title {
        h3{
          text-transform: none;
          font-size: 30px;
          margin-top: 0;
          margin-bottom: 20px;
          &:after {
            display: none;
          }
        }
      }

      &_sub-title {
        h3 {
          margin-top: 0;
          font-size: 21px;
        }
      }

      &-row {
        margin-bottom: 60px;
      }

    }
    &-post-item {
      margin-bottom: 20px;
      a {
        &:hover {
          .hover-image {
            opacity: 1;
          }
        }
      }
      &-image {
        margin-bottom: 5px;
        height: 151px;
        position: relative;
      }
      h3 {
        font-size: 18px;
        border-bottom: 1px solid @blue;
        display: inline;
        font-weight: 400;
      }
    }
  }

  .paginate_links {
    margin-top: 70px;
  }

  .subscribe-blog {
    background-color: #e7f1ff;
    padding: 40px 40px 35px 40px;
    border-radius: 16px;
    margin-top: 70px;
    h3 {
      font-size: 30px;
      font-weight: bold;
      margin-top: 0;
      max-width: 610px;
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 20px;
    }
    p{
      font-size: 18px;
      max-width: 560px;
      margin: 20px 0 10px 0;
    }
    div.sp-form[sp-id="216543"],
    div.sp-form[sp-id="156261"] {
      background-color: transparent;
      .sp-field{
        &[sp-id="sp-3bf6ab82-42ff-4522-9329-726f4c09bcf7"],
        &[sp-id="sp-b0827e67-6040-40cc-ac0e-b72dd02bdf95"]{
          width: 37%;
          margin-right: 1%;
          input {
            background-color: #fff !important;
            border: 0 !important;
            outline: none !important;
          }
          ::placeholder {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.2);
          }
        }
        &[sp-id="sp-c402fe1f-79be-40ff-9259-8dbe4fdda5ba"]{
          display: flex;
          flex: 0 0 22%;
          max-width: 22%;
          margin-right: 0;
          margin-left: auto;
          button {
            width: 100%;
            background-color: @blue;
            border-radius: 30px;
            text-align: center;
          }
        }
      }
    }
  }

}

@media screen and (min-width: 993px){
  .section-knowledge-base article:hover {
    .hover-image {
      opacity: 1;
    }
    a {
      color: #194f90;
      border-color: #194f90!important;
    }
  }
}

@media screen and (max-width: 1199px){
  .section-knowledge-base {
    article .article-thumbnail {
      height: 380px;
    }
    .subscribe-blog div.sp-form[sp-id="216543"] form,
    .subscribe-blog div.sp-form[sp-id="156261"] form {
      flex-direction: column;
      .sp-field[sp-id=sp-3bf6ab82-42ff-4522-9329-726f4c09bcf7],
      .sp-field[sp-id=sp-b0827e67-6040-40cc-ac0e-b72dd02bdf95],
      .sp-field[sp-id=sp-c402fe1f-79be-40ff-9259-8dbe4fdda5ba]{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .section-knowledge-base {
    .subscribe-blog {
      display: none;
    }
  }
}

@media screen and (max-width: 768px){

  .section-knowledge-base {

    h1 {
      margin-top: 15px;
      font-size: 26px;
      padding-bottom: 15px;
    }

    article {
      .article-thumbnail {
        height: 280px;
      }
      .article-details {
        padding: 15px;
        h2 {
          font-size: 18px;
        }
        p {
          margin-top: 10px;
        }
      }
    }

    .selected {
      &-posts {
        &-row {
          margin-bottom: 60px;
          .col {
            max-width: 100%;
            flex: 0 0 100%;
            .selected-post-item-image {
              display: none;
            }
          }
        }
      }
    }

  }

}

@media screen and (max-width: 575px) {
  .section-knowledge-base article .article-details .date {
    padding-left: 0;
  }
}

@media screen and (max-width: 476px) {

  .section-knowledge-base article .article-thumbnail {
    height: 200px;
  }

}
