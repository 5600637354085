.section-client-types-list {
  background-color: #f7f7f7;
  padding-bottom: 60px;
}

.client-types-list {

  ul {
    margin-top: 0 !important;
    margin-bottom: 30px;
    h3 {
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 16px;
    }

    &.columns-2 {

      @media screen and (min-width: 992px) {
        columns: 2;
      }
    }

  }

  .list-style-none {
    list-style: none;
    padding-left: 0;
  }

  a {
    text-decoration: underline;
    font-size: 18px;
    line-height: 1.78;
  }
}