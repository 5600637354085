// Sertification

.section-certification {

  padding-bottom: 70px;

  &.gray {
    background-color: #f7f7f7;
  }

  h4 {
    font-size: 18px;
    margin-top: 30px;
    text-align: inherit;
    margin-bottom: 0;
    font-weight: normal;
    min-height: 42px;
  }

  .button {
    display: inline-block;
    &-wrap {
      display: grid;
      justify-content: end;
      align-items: center;
    }
  }

  span {
    color: rgba(0, 0, 0, 0.2);
  }

  .certification-item {
    margin-top: 25px;
  }

  .white-box, .grey-box {

    border-radius: 16px;
    background-color: @white;
    padding: 30px 25px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img {
      max-height: 99px;
      width: auto;
    }

  }
  .grey-box {
    background-color: @grey;
  }

  .simple-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;

    margin-top: 65px;

    h4 {
      margin-top: 0;
    }

    img {
      margin-right: 29px;
      max-height: 87px;
      width: auto;
    }

  }

  .certifications-list {

    list-style: none;
    margin-top: 65px;
    padding: 0;

    li{
      display: inline;
      width: auto;
      position: relative;
      font-size: 18px;
      line-height: 32px;

      i {
        display: inline-block;
        //width: 6px;
        //height: 6px;
        //border-radius: 50%;
        //background-color: #000;
        position: relative;
        margin-right: 30px;
        height: 18px;
        width: 1px;
        &:after{
          content: "";
          width: 6px;
          height: 6px;
          -webkit-border-radius: 90px;
          -moz-border-radius: 90px;
          border-radius: 90px;
          background-color: #000;
          position: absolute;
          right: -19px;
          top: 9px;
        }
      }

    }

  }

}

@media screen and (max-width: 991px) {
  .section-certification {
    .simple-box {
      margin-top: 35px;
    }
  }
}

@media screen and (max-width: 767px) {

  .section-certification {
    .white-box, .grey-box {
      padding: 25px;
    }
    .simple-box {
      margin-top: 25px;
      flex-direction: column;
      width: 100px;
      margin-left: auto;
      margin-right: auto;
      img {
        margin: 0;
      }
      h4 {
        font-weight: normal;
        margin-top: 10px;
      }
    }
    .certifications-list {
      margin-top: 35px;
      li {
        display: block;
        & i {
          display: none;
        }
      }
    }
  }

}


@media screen and (max-width: 575px) {

  .section-certification {

    padding-bottom: 20px;
    .button-wrap {
      margin-top: 25px;
    }

    h4 {
      font-size: 14px;
      margin-top: 18px;
      text-align: inherit;
      margin-bottom: 0;
    }
    span {
      color: #000;
      opacity: .4;
    }
    .white-box img {
      max-height: 90px;
    }
    .simple-box {
      width: 60px;
      img {
        margin-right: 0;
        max-height: 60px;
      }
      h4 {
        //text-align: center;
      }
    }
    .certifications-list li {
      font-size: 14px;
      line-height: 28px;
    }
  }

}
